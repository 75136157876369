/* CORES DO PROJETO */

$primary: #086C7B;
$secondary: #53B67F;
$text-primary: #395E66;
$grey: #9BADB1;
$light: #E8E8E8;
$white: #FFFFFF;

$success: #52DE97;
$warning: #E3655B;
$alert: #F7D695;
$support: #9F83FE;