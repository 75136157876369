@import './../../styles/scss/Variables.scss';

.invest-realizados-status-0 {
  background: rgba(136, 158, 163, 0.25) !important;
  color: #889EA3 !important;
}

.invest-realizados-status-1 {
  background: rgba(136, 158, 163, 0.25) !important;
  color: #889EA3 !important;
}

.invest-realizados-status-2 {
  background: rgba(72, 130, 134, 0.15) !important;
  color: #488286 !important;
}

.invest-realizados-status-3 {
  background: rgba(58, 124, 165, 0.2) !important;
  color: #3A7CA5 !important;
}

.invest-realizados-status-4 {
  background: #F7D695 !important;
  color: #FFFFFF !important;
}

.invest-realizados-status-5 {
  background: rgba(0, 0, 0, 0.2) !important;
  color: #FFFFFF !important;
}

.invest-realizados-status-6 {
  background: rgba(201, 112, 100, 0.2) !important;
  color: #C97064 !important;
}

.invest-realizados-status-8 {
  background: rgba(58, 124, 165, 0.2) !important;
  color: #3A7CA5 !important;
}

.invest-realizados-status-9 {
  background: rgba(0, 0, 0, 0.2) !important;
  color: #FFFFFF !important;
}

.destaqueTaxaMedia {
  background: $secondary;
  padding: 0.85rem;
  border-radius: 0.35rem;

  * {
    color: $white;
  }
}

.destaqueCharts {
  background: $light;
  padding: 0.85rem;
  border-radius: 0.35rem;
}

// .relativeChartTotalJus {
//   position: relative;
//   height: 100%;
//   width: 100%;
//   padding-bottom: 3rem;
// }

#chartTotalJus {
  // position: absolute;
  // height: 100%;
  // width: 100%;

  canvas {
    width: 100% !important;
    height: 350px !important;
  }
}