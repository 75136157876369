@import './Variables.scss';

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 24px;
  display: inline-block;
  color: rgba($color: $text-primary, $alpha: 1);
  font-weight: bold;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  border-radius: .4em;
  background: transparent;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: rgba($color: $primary, $alpha: 1);
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: .2em;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.drop-container {
  margin-bottom: .7em;

  &.drop-success .dropzone {
    border-color: #00e676;
    background-color: rgba(0, 230, 118, 0.1);
    color: #00e676 !important;
  }

  &.drop-error .dropzone {
    border-color: #ff1744;
    background-color: rgba(255, 23, 68, 0.1);
    color: #ff1744 !important;
  }

  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: .4em;
    border-color: #eeeeee;
    font-weight: 600;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    cursor: pointer;
    outline: none;
    transition: border .24s ease-in-out;

    &:hover {
      border-color: rgba($color: $primary, $alpha: 1);
    }
  }
}

.drop-file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: .7em;
  margin-bottom: .2em;
  font-size: .85em;

  &:last-child {
    margin-bottom: 0;
  }

  img {
    max-width: 20px;
    width: 100%;
    height: auto;
    margin-right: 8px;
  }

  .drop-file-item-inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  button {
    margin-left: 8px;
    width: 30px;
    height: 30px;
  }
}

.p-inputnumber {
  width: 100%;
}

.p-inputtext {
  width: 100%;
  border: 1.5px solid $grey;
  outline: none;
  padding: 0 0.8em;
  border-radius: .4em;
  height: 45px;
  line-height: 45px;
}

.p-dropdown {
  width: 100%;
  height: 45px;

  .p-inputtext {
    width: 100%;
    border: 0;
    outline: none;
    padding: 0.85rem 0.85rem;
    border-radius: .4em;
    height: auto;
    line-height: 1;
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: $primary !important;
  background: rgba($color: $primary, $alpha: 0.12) !important;
}

.p-paginator .p-dropdown {
  width: auto !important;
}

textarea.p-inputtext {
  line-height: 1.3rem;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  max-width: 100%;
  min-width: 100%;
}

.p-inputtext:enabled:focus {
  -webkit-box-shadow: inset 0 0 0 1px $primary, inset 0 0 0 1px $primary, inset 0 0 0 1px $primary, inset 0 0 0 1px $primary !important;
  box-shadow: inset 0 0 0 1px $primary, inset 0 0 0 1px $primary, inset 0 0 0 1px $primary, inset 0 0 0 1px $primary !important;
  border-color: $primary !important;
}

.p-float-label input:focus~label,
.p-float-label .p-inputwrapper-focus~label {
  color: $primary !important;
}

.p-inputtext:enabled:focus.p-invalid {
  box-shadow: inset 0 0 0 1px $warning, inset 0 0 0 1px $warning, inset 0 0 0 1px $warning, inset 0 0 0 1px $warning !important;
}

.p-inputtext.p-invalid.p-component {
  border-color: $warning !important;
}

.p-float-label label {
  top: 1rem !important;
  margin-top: 0 !important;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-float-label input:focus~label.p-error {
  color: $warning !important;
}

.p-field-checkbox,
.p-field-radiobutton {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.p-field-checkbox>label,
.p-field-radiobutton>label {
  margin-left: .5rem;
  line-height: 1;
}

.p-field-checkbox.input-checkbox {
  height: 45px;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.5;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: rgba($color: $primary, $alpha: 1) !important;
  background: rgba($color: $primary, $alpha: 1) !important;
}

.p-error {
  color: $warning !important;
}