.card {
  pointer-events: all !important;
}

.p-paginator .p-paginator-current {
  margin-left: auto;
}

.p-progressbar {
  height: .5rem;
  background-color: #D8DADC;
}

.p-progressbar .p-progressbar-value {
  background-color: #607D8B;
}

.table-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.p-datepicker {
  min-width: 25rem;
}

.p-datepicker td {
  font-weight: 400;
}

.p-datatable.p-datatable-customers .p-datatable-header {
  padding: 1rem;
  text-align: left;
  font-size: 1.5rem;
}

.p-datatable.p-datatable-customers .p-datatable-thead>tr>th {
  position: relative;
}

.p-datatable.p-datatable-customers .p-paginator {
  padding: 1rem;
}

.p-datatable.p-datatable-customers .p-datatable-thead>tr>th {
  text-align: left;
}

.p-datatable.p-datatable-customers .p-datatable-tbody>tr>td {
  cursor: auto;
}

.p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
  text-transform: uppercase;
}

.p-datatable-customers .p-datatable-tbody>tr>td .p-column-title {
  display: none;
}

@media screen and (max-width: 960px) {

  .p-datatable.p-datatable-customers .p-datatable-thead>tr>th,
  .p-datatable.p-datatable-customers .p-datatable-tfoot>tr>td {
    display: none !important;
  }

  .p-datatable.p-datatable-customers .p-datatable-tbody>tr {
    border-bottom: 1px solid var(--layer-2);
  }

  .p-datatable.p-datatable-customers .p-datatable-tbody>tr>td {
    text-align: left;
    display: block;
    border: 0 none !important;
    width: 100% !important;
    float: left;
    clear: left;
    border: 0 none;
  }

  .p-datatable.p-datatable-customers .p-datatable-tbody>tr>td .p-column-title {
    padding: .4rem;
    min-width: 30%;
    display: inline-block;
    margin: -.4rem 1rem -.4rem -.4rem;
    font-weight: bold;
  }

  .p-datatable.p-datatable-customers .p-datatable-tbody>tr>td .p-progressbar {
    margin-top: .5rem;
  }
}