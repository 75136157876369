@import './../../styles/scss/Variables.scss';

.stepper-footer {
  position: sticky;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: 0.4rem 0.6rem;
  box-shadow: 0 -8px 6px -10px rgba(0, 0, 0, 0.3), 0 -6px 14px -12px rgba(0, 0, 0, 0.14), 0 -2px 18px -16px rgba(0, 0, 0, 0.12);

  .start {
    justify-content: flex-start;
    flex: 0;
  }

  .end {
    justify-content: flex-end;
    flex: 0;
  }
}

.stepper-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stepper-header-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;

  &:last-child {
    flex: 0 40px;
  }
}

.stepper-item-wrapper {
  white-space: normal;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;
  overflow-y: hidden;
  max-height: 100%;
  padding-bottom: 2rem;

  .stepper-item {
    width: 100%;
    min-width: 100%;
    padding: 0 .5em;
  }
}

@media screen and (min-width: 768px) {
  .stepper-item-wrapper {
    white-space: nowrap;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    overflow: hidden;
    overflow-y: hidden;
    max-height: 100%;

    .stepper-item {
      width: 100%;
      min-width: 100%;
      padding: 0 .5em;
    }
  }
}

.stepper-number {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: bold;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  background: $primary;
  background: linear-gradient(70deg, rgba($color: $primary, $alpha: 1), rgba($color: $secondary, $alpha: 1));
  color: #FFF;
}

.stepper-items-inner {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepper-label {
  color: rgba($color: $primary, $alpha: 1);
  position: relative;
  text-transform: uppercase;
  font-size: .7em;
  line-height: 1.4;
  bottom: 20px;
  width: 100%;
  text-align: center;
  white-space: normal;
}

.stepper-header-wrapper.active {
  .stepper-number {
    background: #FFF;
    border: 2px solid rgba($color: $primary, $alpha: 1);
    color: $primary;
  }
}

.stepper-header-wrapper.standby {
  .stepper-number {
    background: $light;
    color: #FFF;
  }
}

.stepper-header-wrapper.finished {
  .stepper-number {
    background: $primary;
    background: linear-gradient(70deg, rgba($color: $primary, $alpha: 1), rgba($color: $secondary, $alpha: 1));
    color: #FFF;
  }
}