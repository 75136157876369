@import './../../styles/scss/Variables.scss';
$menu-mobile: calc(100% - 90px);

.menuOpened {
  overflow: hidden;

  .mobile-menu {
    animation: openLeftMenu .355s ease-in-out;
  }
}

.menuClosed {
  overflow: auto;

  .mobile-menu {
    left: -100%;
    animation: closeLeftMenu .355s ease-in-out;
  }
}

.menu-content-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.menu-header {
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 70px;
  background: linear-gradient(90deg, $primary, $secondary);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
}

.menu-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  height: 70px;
  padding: 0 1em;
}

.menu-user .name {
  color: $light;
  font-weight: bold;
}

.menu-user .profile {
  color: $light;
  opacity: .75;
}

.menu-user .avatar {
  min-height: 60px;
  align-items: center;
  justify-content: flex-end;
}

.menu-user .avatar img {
  height: auto;
  width: 100%;
  max-width: 45px;
}

.main-content-wrapper {
  height: auto;
  min-height: 100vh;
  min-width: 100%;
  padding: calc(70px + 1.3em) 1em 6em;
  left: 0;
  background: #F9F9FB;
}

.menu-item-wrapper {
  display: none;
}

.menu-link {
  color: rgba($primary, 0.4);
}

.menu-link.active {
  color: rgba($primary, 1);
}

.menu-link a {
  padding: 1.3em 1.6em;
  color: inherit;
  text-decoration: none;
  font-weight: 900;
}

@media screen and (min-width: 768px) {
  .main-content-wrapper {
    padding: 2em 50px 0 230px;
  }

  .menu-content-wrapper {
    position: absolute;
    height: 100%;
    width: auto;
    flex-direction: row;
  }

  .menu-header {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 180px;
    background: linear-gradient(0deg, $primary, $secondary);
    position: fixed;
    left: 0;
  }

  .menu-item-wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    margin-top: 30px;
  }

  .menu-user {
    flex-direction: column;
    height: 130px;
  }

  .menu-user .avatar {
    min-height: 80px;
    align-items: center;
    justify-content: center;
  }

  .mobile-menu {
    display: none;
  }

  .mobile-menu[data-collapse='true'] {
    display: none;
  }

  .menu-link {
    color: rgba($light, 0.4);
  }

  .menu-link.active {
    color: rgba($light, 1);
    background: rgba($light, 0.09);
  }

  .menu-link a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

@media screen and (min-width: 1092px) {
  .main-content-wrapper {
    padding: 2em 80px 0 260px;
  }
}

/* ------------------------------------------------------------------------------------------------ */
.mobile-menu {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  min-height: calc(101vh - 70px);
  background: rgba($white, 1);
  flex: 0;
  z-index: 998;
  position: fixed;
  top: 70px;
  left: -100%;

  .visual-side {
    flex: 0;
    height: calc(101vh - 70px);
    min-width: 30px;
    background: linear-gradient(0deg, $primary, $secondary);
  }

  .menu-mobile-items {
    padding: 0 2em;
  }
}

.mobile-menu[data-collapse='false'] {
  position: fixed;
  top: 70px;
  left: 0;
  animation: openLeftMenu .355s ease-in-out;
  z-index: 998;
}

.backdrop-mobile-menu[data-collapse='false'] {
  transition: .355s ease-in-out;
  width: 100%;
  min-height: 101vh;
  background: rgba($primary, .7);
  flex: 0;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
}

@keyframes openLeft {
  0% {
    left: 0;
  }

  100% {
    left: $menu-mobile;
  }
}

@keyframes openLeftMenu {
  0% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}

@keyframes closeLeft {
  0% {
    left: $menu-mobile;
  }

  100% {
    left: 0;
  }
}

@keyframes closeLeftMenu {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

.bg-transparent {
  background: transparent;
}