@import './../../styles/scss/Variables.scss';

.card.panel {
  min-height: 60px;
  pointer-events: all;

  &.warn {
    border-left: 6px solid #B12A5B;
  }

  &.success {
    border-left: 6px solid $primary;
  }
}

.panel-title {
  text-transform: uppercase;
}

.panel.warn .panel-label {
  font-size: 85%;
  border-radius: 30px;
  padding: .2em 1em;
  background: rgba($warning, $alpha: .2);
  color: $warning;
}

.panel.success .panel-label {
  font-size: 85%;
  border-radius: 30px;
  padding: .2em 1em;
  background: rgba($primary, $alpha: .2);
  color: $primary;
}