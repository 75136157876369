footer {
  position: absolute;
  z-index: 999;
  padding: 1em;
  margin-top: -10px;
  border-radius: 0.6rem 0.6rem 0 0;
  color: white;
  background: #53b67f;
  background: -o-radial-gradient(circle, #53b67f 0%, #086c7b 100%);
  background: radial-gradient(circle, #53b67f 0%, #086c7b 100%);

  p {
    color: inherit !important;
  }

  @media (max-width: 767px) {
    margin-top: 0px;
  }
}