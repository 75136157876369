.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  >* {
    flex: 1;
  }

  @for $i from 0 through 4 {
    .flex-#{$i} {
      -webkit-box-flex: $i;
      -ms-flex: $i;
      flex: $i;
    }
  }
}

.flex-column {
  flex-direction: column;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-inherit {
  align-items: inherit;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.wrapper-page {
  height: 100%;
  min-height: 100vh;
  position: relative;
}

@for $i from 1 through 4 {
  .flex-#{$i} {
    -webkit-box-flex: $i;
    -ms-flex: $i;
    flex: $i;
  }
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

@media screen and (min-width: 768px) {
  .d-sm-none {
    display: none;
  }

  .d-sm-block {
    display: block;
  }

  .d-sm-flex {
    display: flex;
  }
}

.spaced-items>* {
  margin-right: 8px;
  margin-bottom: 8px;
}

.spaced-items>*:last-child {
  margin-right: 0px;
  margin-bottom: 8px;
}