@import './../../styles/scss/Grid.scss';
@import './../../styles/scss/Variables.scss';
@import './../../styles/scss/Text.scss';

.input-wrapper {
  margin-bottom: 1.5em;
}

.input-inner {
  @extend .flex;
  @extend .flex-column;
  position: relative;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  width: 100%;
  background: white;
  border-radius: .4em;

  select {
    border: 1.5px solid $grey;
    color: $grey;
    padding: 0 1em;
    height: inherit;
    outline: none;
    font-size: inherit;
    width: inherit;
    border-radius: inherit;
    transition: .2s ease-in-out;

    &:focus {
      border: 1.5px solid #ddd;
      color: #000;
    }

    &.has-value {
      color: #000;
    }

    &.invalid-value {
      border: 1.5px solid $warning;
      color: $warning;
    }
  }

  label {
    display: inline-block;
    position: absolute;
    vertical-align: middle;
    pointer-events: none;
    height: auto;
    line-height: 1.2;
    left: 1.2em;
    color: $grey;
    transition: .2s ease-in-out;
  }
}

.input-helper {
  @extend .lead;
  text-align: right;
  width: 100%;
}