.p-button {
  background: $primary !important;
  font-family: 'Muli', sans-serif !important;
  outline: none;
  box-shadow: none;
  padding: .7rem 1rem !important;
  border-radius: 0.5em !important;
  font-size: 1rem;
  font-weight: 500 !important;

  &:enabled:hover {
    background: rgba($color: $primary, $alpha: 0.85) !important;
  }


  &:enabled:active,
  &:enabled:focus {
    background: rgba($color: $primary, $alpha: 0.75) !important;
  }
}

.p-dropdown:not(.p-disabled).p-focus {
  -webkit-box-shadow: inset 0 0 0 1px $primary, inset 0 0 0 1px $primary, inset 0 0 0 1px $primary, inset 0 0 0 1px $primary !important;
  box-shadow: inset 0 0 0 1px $primary, inset 0 0 0 1px $primary, inset 0 0 0 1px $primary, inset 0 0 0 1px $primary !important;
  border-color: $primary !important;
}

.p-button.p-button-text {
  background: transparent !important;
  color: $primary !important;

  &:enabled:hover {
    background: rgba($color: $primary, $alpha: 0.25) !important;
  }


  &:enabled:active,
  &:enabled:focus {
    background: rgba($color: $primary, $alpha: 0.15) !important;
  }
}

.p-tag {
  background: $primary !important;
}