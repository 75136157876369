@import './../../styles/scss/Variables.scss';

.panel {
  min-height: 60px;

  &.warn {
    border-left: 6px solid #B12A5B;
  }

  &.success {
    border-left: 6px solid $primary;
  }
}

.panel-title {
  text-transform: uppercase;
}

.panel.warn .panel-label {
  font-size: 85%;
  border-radius: 30px;
  padding: .2em 1em;
  background: rgba($warning, $alpha: .2);
  color: $warning;
}

.panel-label.alert {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 1.2em !important;
  border-radius: .35em !important;
  background: rgba($support, $alpha: 1) !important;
  color: #FFF !important;
  margin-top: 10px;
  padding: .5em 1.3em !important;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: .2s ease-in;
  pointer-events: all;

  label {
    margin-left: 6px;
  }

  * {
    flex: auto !important;
    cursor: pointer;
    pointer-events: all;
  }

  &:hover {
    background: rgba($support, $alpha: 0.75) !important;
  }
}

.panel.success .panel-label {
  font-size: 85%;
  border-radius: 30px;
  padding: .2em 1em;
  background: rgba($primary, $alpha: .2);
  color: $primary;
}

.panel-step {
  flex: 0;
}

.panel-step .number {
  min-width: 26px;
  min-height: 26px;
  flex: 0;
  background: rgba($grey, $alpha: .4);
  border: 2px solid rgba($grey, $alpha: .1);
  color: #FFFFFF;
  border-radius: 50%;
  line-height: 0;
  vertical-align: middle;
  font-weight: bold;

  &.active {
    background: transparent;
    border: 2px solid rgba($primary, $alpha: 1);
    color: #000;
  }
}

.panel-step::after {
  content: '';
  display: flex;
  width: 20px;
  height: 2px;
  background: rgba($grey, $alpha: .3);
}

.panel-step:last-child::after {
  content: '';
  display: flex;
  width: 0px;
  height: 0px;
  background: rgba($grey, $alpha: 0);
}