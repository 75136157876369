.side-img-cad {
  width: auto;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
}

@media (min-width: 768px) {
  .side-img-cad {
    top: 0;
    left: -20%;
  }
}

@media (min-width: 1024px) {
  .side-img-cad {
    top: 0;
    left: -0;
  }
}

.col-1 {
  flex: 1;
}

.col-2 {
  flex: 2;
}
