/* GLOBAL STYLES HERE */

@import './fonts/Fonts.scss';
@import './Variables.scss';
@import './Grid.scss';
@import './Spacing.scss';
@import './Forms.scss';
@import './Button.scss';

@import './../css/DataTable.css';
@import './EndRegister.scss';

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-size: 14px;
  font-family: 'Muli',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-table {
  .MuiPaper-root {
    background-color: transparent;
  }

  .MuiTable-root,
  .p-datatable-wrapper table {
    width: 100%;
    display: table;
    border-spacing: 0px 10px;
    border-collapse: separate;
  }

  .p-datatable .p-datatable-header {
    background: transparent;
    border-bottom: 0px;
    padding: 0px !important;
  }

  .MuiTableRow-hover {
    cursor: pointer;
  }

  .MuiTableRow-root.success,
  .MuiTableRow-root.ativo,
  .MuiTableRow-root.green {
    .MuiTableCell-root {

      &:nth-child(1) {
        border-left: 4px solid $primary;
      }
    }
  }

  .MuiTableRow-root.warn,
  .MuiTableRow-root.inativo,
  .MuiTableRow-root.red {
    .MuiTableCell-root {

      &:nth-child(1) {
        border-left: 4px solid $warning;
      }
    }
  }

  .MuiTableRow-root.off,
  .MuiTableRow-root.standby,
  .MuiTableRow-root.grey {
    .MuiTableCell-root {

      &:nth-child(1) {
        border-left: 4px solid $grey;
      }
    }
  }

  .MuiTableCell-root,
  .p-datatable .p-datatable-tbody>tr>td {
    border-bottom: none;
    background: $white;
    font-size: 15px;

    &:nth-child(1) {
      border-radius: .4em 0 0 .4em;
    }

    &:nth-last-child(1) {
      border-radius: 0 .4em .4em 0;
    }
  }

  .p-datatable .p-datatable-thead>tr>th {
    background: transparent;
  }

  .MuiTableCell-head,
  .p-column-title {
    color: rgba(0, 0, 0, 0.87);
    font-size: .80rem;
    font-weight: 900;
    line-height: 1.5rem;
    text-transform: uppercase;
    background: transparent;
    border-left: 0px !important;
  }

  .p-datatable .p-paginator {
    padding: 1rem;
    background: transparent;
    border: 0px;
  }
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: rgba($color: $primary, $alpha: 0.1) !important;
  border-color: rgba($color: $primary, $alpha: 0.1) !important;
  color: $primary !important;
}

.p-component {
  font-family: 'Muli', sans-serif !important;
}

.MuiDialogContent-root {
  padding: 0 1.4em 1em !important;
}

.MuiDialog-paper {
  margin: 30px 16px !important;
}

.MuiDialog-paperWidthSm {
  max-width: 800px !important;
  width: 98vw;
}

// PROGRESS

.MuiLinearProgress-root {
  height: 8px !important;
  border-radius: .25em !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: rgba($color: $primary, $alpha: .15) !important;
}

.MuiLinearProgress-barColorPrimary {
  background: linear-gradient(90deg, rgba($color: $secondary, $alpha: .85), rgba($color: $primary, $alpha: 1)) !important;
}

// ICONES

.icon-holder {
  background: transparent;
  border-radius: .35em;
  position: relative;
  line-height: 1;

  &.padding {
    padding: .35em;
  }

  &.gradient {
    background: linear-gradient(90deg, rgba($color: $primary, $alpha: 1), rgba($color: $secondary, $alpha: 1));
    color: #FFF;
  }

  &.warn {
    background: rgba($color: $warning, $alpha: 1);
    color: #FFF;
  }

  &.success {
    background: rgba($color: $primary, $alpha: 1);
    color: #FFF;
  }
}

[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  vertical-align: middle;
}

// MODAL, DIALOGS

.custom-modal-backdrop {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba($color: rgba(0, 0, 0, 0.87), $alpha: .5);
  z-index: 999;
  padding: 1em;
  opacity: 1;
  animation: backdropFade 0.7s cubic-bezier(0.075, 0.82, 0.165, 1);

  @media (min-width: 768px) {
    padding: 0;
  }
}

.custom-modal,
.p-dialog {
  position: relative;
  bottom: 0;
  opacity: 1;
  width: 100vw;
  height: auto;
  max-width: 700px;
  height: auto;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 0.5em;
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff;
  padding: 1em;
  animation: modalSlideUp 0.3s cubic-bezier(0.215, 0.610, 0.355, 1);

  @media (min-width: 768px) {
    padding: 2em;
  }
}

.p-dialog {
  padding: 0;
}

.p-dialog-content {
  font-size: 88%;
}

@keyframes modalSlideUp {
  0% {
    bottom: -70vh;
  }

  100% {
    bottom: 0;
  }
}

@keyframes backdropFade {
  0% {
    background: rgba($color: rgba(0, 0, 0, 0.87), $alpha: .0001);
  }

  100% {
    background: rgba($color: rgba(0, 0, 0, 0.87), $alpha: .5);
  }
}

.modal-content.withdraw,
.modal-content.add {
  .content-body {
    margin-top: 2em;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    >div {
      flex: 2;
    }

    >div:last-child {
      flex: 1;
    }

    @media screen and (min-width: 768px) {
      >div {
        flex: 1;
      }

      >div:last-child {
        flex: 1;
      }
    }
  }

  .content-item {
    text-transform: uppercase;
    margin-bottom: 1em;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 120px;
    float: right;

    @media screen and (min-width: 768px) {
      max-width: 300px;
    }
  }
}

// IMAGES

.img-full {
  display: block;
  width: 100%;
}

.img-holder {
  overflow: hidden;
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: .45em;

  .media-img {
    position: absolute;
    height: inherit;
    width: inherit;
  }
}

$stroke: 6px;
$duration-bars: 1s;
$duration-donut: .8s;

@keyframes donut {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }
}

.donut {
  border-radius: 100%;
  border-style: solid;
  border-width: $stroke * 0.6;
  border-top-color: $secondary;
  border-right-color: $secondary;
  border-bottom-color: $secondary;
  border-left-color: $light;
  width: $stroke * 4.1;
  height: $stroke * 4.1;
  animation-duration: $duration-donut;
  animation-name: donut;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}

.doc-download {
  position: relative;
  box-sizing: border-box;
  display: block;
  background: #eee;
  border-radius: 0.45em;
  padding: 1em;
  margin-bottom: 1em;
}

.status-badge {
  display: block;
  background: rgba(136, 158, 163, 0.25);
  color: #889EA3;
  padding: 0.7em 0.9em;
  border-radius: 30px;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.65rem;

  &.status-grey {
    background: rgba(136, 158, 163, 0.25) !important;
    color: #889EA3 !important;
  }

  &.status-green {
    background: rgba(90, 170, 149, 0.2) !important;
    color: #5AAA95 !important;
  }

  &.status-red {
    background: rgba(201, 112, 100, 0.2) !important;
    color: #C97064 !important;
  }
}

.status-badge-user {
  display: block;
  background: rgba(136, 158, 163, 0.25);
  color: #889EA3;
  padding: 0.7em 0.9em;
  border-radius: 30px;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.65rem;

  &.status-grey {
    background: rgba(136, 158, 163, 0.25) !important;
    color: #889EA3 !important;
  }

  &.status-green,
  &.status-1 {
    background: rgba(90, 170, 149, 0.2) !important;
    color: #5AAA95 !important;
  }

  &.status-red,
  &.status-0 {
    background: rgba(201, 112, 100, 0.2) !important;
    color: #C97064 !important;
  }
}

.trending-up {
  color: $success;
  filter: brightness(0.9);
}

.trending-down {
  color: $warning;
}

/* TABLES */

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,
.p-datatable .p-sortable-column.p-highlight .p-column-title {
  color: rgba($color: $primary, $alpha: 1.0) !important;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  display: inline;
}

.list-docs-wrapper {
  display: block;
  width: 100%;

  .list-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: 0;
    }

    .list-column {
      padding: 0.4rem;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &.main-column {
        flex: 1;
      }
    }
  }
}

/* BACKOFFICE */

.accordion-backoffice i,
.accordion-backoffice span {
  vertical-align: middle;
}

.accordion-backoffice span {
  margin: 0 .5rem;
}

.p-accordion p {
  line-height: 1.5;
  margin: 0;
}

.p-accordion .p-accordion-tab.p-accordion-tab-active {
  margin-bottom: 0;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1em 1.4em;
}

.bo-list-values {
  display: flex;
  align-items: center;
  padding: 0.4em;

  label {
    font-size: 70%;
    font-weight: 700;
    text-transform: uppercase;
  }

  .bo-list-square {
    display: block;
    width: 10px;
    height: 10px;
    margin: 0;
  }
}