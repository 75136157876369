@import './../../styles/scss/Variables.scss';

.card {
  height: 100%;
  min-height: 160px;
  border-radius: .6em;
  padding: 1em;
  box-shadow: 0 1px 8px -1px rgba(0, 0, 0, .02), 0 1px 8px 0 rgba(0, 0, 0, .03), 0 1px 5px 0 rgba(0, 0, 0, .05);
  background: #FFF;
  position: relative;
  pointer-events: none;

}

.card.dark {
  background: $primary;

  * {
    color: white !important;
  }
}

.card.primary {
  background: $primary;
  background: linear-gradient(90deg, rgba($color: $primary, $alpha: 1), rgba($color: $secondary, $alpha: 1));

  * {
    color: white !important;
  }
}

.card .dark-overlay {
  background: url('./../../assets/img/card-clip.png') no-repeat;
  background-size: cover;
  background-position: 50% 50px;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
}

.card-label {
  text-transform: uppercase;
  font-weight: 900;
  font-size: .85em;
  flex: 0;
}

.card-main-value {
  margin: 20px 0;

  >h3 {
    font-size: 1.5em;
  }
}

.card-buttons {
  button {
    margin-right: 10px;
    min-width: 100px;
    flex: 1 1 !important;
  }

  button:last-child {
    margin-right: 0px;
  }
}

@media screen and (min-width: 768px) {
  .card {
    min-height: 170px;
    border-radius: .6em;
    padding: 1em 2em;
    box-shadow: 0 1px 8px -1px rgba(0, 0, 0, .02), 0 1px 8px 0 rgba(0, 0, 0, .03), 0 1px 5px 0 rgba(0, 0, 0, .05);
    background: #FFF;
    position: relative;
    pointer-events: none;

    &:first-child {
      min-height: 170px;
    }
  }

  .card-main-value {
    margin: 20px 0;

    >h3 {
      font-size: 1.8rem;
    }
  }
}

.card-wrapper .card {
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
}

.card-balance {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  >div:not(.dark-overlay) {
    z-index: 2;
  }
}

.accordion-backoffice .accordion-card {
  min-height: 100px !important;
}