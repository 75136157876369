@import './../../styles/scss/Variables.scss';

.card-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  >* {
    flex: 1 1 100%;
    margin-right: 0 !important;
    margin-bottom: 1em;

    &:first-child {
      flex: 2 100%;
      margin-right: 0 !important;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.investment-card {
  justify-content: flex-start;

  .img-holder {
    height: 50px;
    width: 50px;
  }

  label {
    text-transform: uppercase;
    flex: 1;
    font-size: .75em;
  }

  h6 {
    text-transform: uppercase;
    margin: 0;
    font-size: 1.1em;
  }

  .text-spread {
    text-transform: initial;
  }
}

.chart-wrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;

  &.area-graphic {
    margin-left: 0;
    margin-right: 0;
  }

  &.round-graphic {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }

  .chart-labels {
    flex: 3 1 100%;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 0;
    padding-right: 1em;

    label {
      display: block;
      color: #2E384D;

      &.label-previsto::before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 6px;
        background: $grey;
        border-radius: 50%;
      }

      &.label-arrecadado::before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 6px;
        background: linear-gradient(90deg, rgba($color: $primary, $alpha: 1), rgba($color: $secondary, $alpha: 1));
        border-radius: 50%;
      }
    }
  }

  @media (min-width: 768px) {
    &.area-graphic {
      margin-left: -1.5em;
      margin-right: -1em;
    }

    &.round-graphic {
      flex-direction: row;
      margin-left: -2em;
      margin-right: -2em;
    }
  }
}

#investment-modal-detail .MuiDialog-paperWidthSm,
#investment-modal-value .MuiDialog-paperWidthSm {
  max-width: 520px !important;
  width: 98vw;

  h6 {
    margin-top: .4em;
  }
}

@media screen and (min-width: 768px) {
  .card-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;

    >* {
      flex: 1;
      margin-right: 1em !important;
      margin-bottom: 1em;

      &:first-child {
        flex: .8;
        margin-right: 1em !important;
      }

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .hideOnMob {
    display: none !important;
  }

  .content-body {
    flex-direction: column !important;
  }
}

.mdl-logout {
  align-items: center;
  justify-content: center;
  width: 100vw;
  max-width: 460px;
  margin: 0 10px;
  border-radius: 0.4rem;
}

.mdl-logout .p-dialog .p-dialog-content {
  background: transparent;
}