@font-face {
  font-family: 'Muli';
  src: local('muli regular'), local('muli-regular'),
    url('./muli-regular.woff2') format('woff2'),
    url('./muli-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: local('muli bold'), local('muli-bold'),
    url('./muli-bold.woff2') format('woff2'),
    url('./muli-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Muli';
  src: local('muli semibold'), local('muli-semibold'),
    url('./muli-semibold.woff2') format('woff2'),
    url('./muli-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: local('overpass regular'), local('overpass-regular'),
    url('./overpass-regular.woff2') format('woff2'),
    url('./overpass-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: local('overpass semibold'), local('overpass-semibold'),
    url('./overpass-semibold.woff2') format('woff2'),
    url('./overpass-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: local('overpass bold'), local('overpass-bold'),
    url('./overpass-bold.woff2') format('woff2'),
    url('./overpass-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}