@media screen and (max-width: 768px) {
  .side-img {
    width: 100% !important;
    max-width: 100% !important;
    height: auto !important;
    overflow: auto;
    margin-top: -22% !important;
  }
}

.col-1 {
  flex: 1;
  width: 100%;
}

.col-2 {
  flex: 2;
  width: 100%;
}

.inverse-mobile {
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .inverse-mobile {
    flex-direction: row;
  }
}

.mobile-text-form {
  position: absolute;
  top: 16vh;
}

.float-btn-login {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 2200;
}