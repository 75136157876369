$space: 0.4rem;

@for $i from 0 through 10 {

  .m-#{$i} {
    margin: $i * $space !important;
  }

  .mt-#{$i},
  .my-#{$i} {
    margin-top: $i * $space !important;
  }

  .mr-#{$i},
  .mx-#{$i} {
    margin-right: $i * $space !important;
  }

  .mb-#{$i},
  .my-#{$i} {
    margin-bottom: $i * $space !important;
  }

  .ml-#{$i},
  .mx-#{$i} {
    margin-left: $i * $space !important;
  }

  .p-#{$i} {
    padding: $i * $space !important;
  }

  .pt-#{$i},
  .py-#{$i} {
    padding-top: $i * $space !important;
  }

  .pr-#{$i},
  .px-#{$i} {
    padding-right: $i * $space !important;
  }

  .pb-#{$i},
  .py-#{$i} {
    padding-bottom: $i * $space !important;
  }

  .pl-#{$i},
  .px-#{$i} {
    padding-left: $i * $space !important;
  }

  .m-n#{$i} {
    margin: -$i * $space !important;
  }

  .mt-n#{$i},
  .my-n#{$i} {
    margin-top: -$i * $space !important;
  }

  .mr-n#{$i},
  .mx-n#{$i} {
    margin-right: -$i * $space !important;
  }

  .mb-n#{$i},
  .my-n#{$i} {
    margin-bottom: -$i * $space !important;
  }

  .ml-n#{$i},
  .mx-n#{$i} {
    margin-left: -$i * $space !important;
  }
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

// @media (min-width: 576px) {
//   @for $i from 0 through 10 {
//     .m-sm-#{$i} {
//       margin: $i * $space !important;
//     }

//     .mt-sm-#{$i},
//     .my-sm-#{$i} {
//       margin-top: $i * $space !important;
//     }

//     .mr-sm-#{$i},
//     .mx-sm-#{$i} {
//       margin-right: $i * $space !important;
//     }

//     .mb-sm-#{$i},
//     .my-sm-#{$i} {
//       margin-bottom: $i * $space !important;
//     }

//     .ml-sm-#{$i},
//     .mx-sm-#{$i} {
//       margin-left: $i * $space !important;
//     }

//     .p-sm-#{$i} {
//       padding: $i * $space !important;
//     }

//     .pt-sm-#{$i},
//     .py-sm-#{$i} {
//       padding-top: $i * $space !important;
//     }

//     .pr-sm-#{$i},
//     .px-sm-#{$i} {
//       padding-right: $i * $space !important;
//     }

//     .pb-sm-#{$i},
//     .py-sm-#{$i} {
//       padding-bottom: $i * $space !important;
//     }

//     .pl-sm-#{$i},
//     .px-sm-#{$i} {
//       padding-left: $i * $space !important;
//     }

//     .m-sm-n#{$i} {
//       margin: -$i * $space !important;
//     }

//     .mt-sm-n#{$i},
//     .my-sm-n#{$i} {
//       margin-top: -$i * $space !important;
//     }

//     .mr-sm-n#{$i},
//     .mx-sm-n#{$i} {
//       margin-right: -$i * $space !important;
//     }

//     .mb-sm-n#{$i},
//     .my-sm-n#{$i} {
//       margin-bottom: -$i * $space !important;
//     }

//     .ml-sm-n#{$i},
//     .mx-sm-n#{$i} {
//       margin-left: -$i * $space !important;
//     }
//   }

//   .m-sm-auto {
//     margin: auto !important;
//   }

//   .mt-sm-auto,
//   .my-sm-auto {
//     margin-top: auto !important;
//   }

//   .mr-sm-auto,
//   .mx-sm-auto {
//     margin-right: auto !important;
//   }

//   .mb-sm-auto,
//   .my-sm-auto {
//     margin-bottom: auto !important;
//   }

//   .ml-sm-auto,
//   .mx-sm-auto {
//     margin-left: auto !important;
//   }
// }

// @media (min-width: 768px) {
//   @for $i from 0 through 10 {
//     .m-md-#{$i} {
//       margin: $i * $space !important;
//     }

//     .mt-md-#{$i},
//     .my-md-#{$i} {
//       margin-top: $i * $space !important;
//     }

//     .mr-md-#{$i},
//     .mx-md-#{$i} {
//       margin-right: $i * $space !important;
//     }

//     .mb-md-#{$i},
//     .my-md-#{$i} {
//       margin-bottom: $i * $space !important;
//     }

//     .ml-md-#{$i},
//     .mx-md-#{$i} {
//       margin-left: $i * $space !important;
//     }

//     .p-md-#{$i} {
//       padding: $i * $space !important;
//     }

//     .pt-md-#{$i},
//     .py-md-#{$i} {
//       padding-top: $i * $space !important;
//     }

//     .pr-md-#{$i},
//     .px-md-#{$i} {
//       padding-right: $i * $space !important;
//     }

//     .pb-md-#{$i},
//     .py-md-#{$i} {
//       padding-bottom: $i * $space !important;
//     }

//     .pl-md-#{$i},
//     .px-md-#{$i} {
//       padding-left: $i * $space !important;
//     }

//     .m-md-n#{$i} {
//       margin: -$i * $space !important;
//     }

//     .mt-md-n#{$i},
//     .my-md-n#{$i} {
//       margin-top: -$i * $space !important;
//     }

//     .mr-md-n#{$i},
//     .mx-md-n#{$i} {
//       margin-right: -$i * $space !important;
//     }

//     .mb-md-n#{$i},
//     .my-md-n#{$i} {
//       margin-bottom: -$i * $space !important;
//     }

//     .ml-md-n#{$i},
//     .mx-md-n#{$i} {
//       margin-left: -$i * $space !important;
//     }
//   }

//   .m-md-auto {
//     margin: auto !important;
//   }

//   .mt-md-auto,
//   .my-md-auto {
//     margin-top: auto !important;
//   }

//   .mr-md-auto,
//   .mx-md-auto {
//     margin-right: auto !important;
//   }

//   .mb-md-auto,
//   .my-md-auto {
//     margin-bottom: auto !important;
//   }

//   .ml-md-auto,
//   .mx-md-auto {
//     margin-left: auto !important;
//   }
// }

// @media (min-width: 992px) {
//   @for $i from 0 through 10 {
//     .m-lg-#{$i} {
//       margin: $i * $space !important;
//     }

//     .mt-lg-#{$i},
//     .my-lg-#{$i} {
//       margin-top: $i * $space !important;
//     }

//     .mr-lg-#{$i},
//     .mx-lg-#{$i} {
//       margin-right: $i * $space !important;
//     }

//     .mb-lg-#{$i},
//     .my-lg-#{$i} {
//       margin-bottom: $i * $space !important;
//     }

//     .ml-lg-#{$i},
//     .mx-lg-#{$i} {
//       margin-left: $i * $space !important;
//     }

//     .p-lg-#{$i} {
//       padding: $i * $space !important;
//     }

//     .pt-lg-#{$i},
//     .py-lg-#{$i} {
//       padding-top: $i * $space !important;
//     }

//     .pr-lg-#{$i},
//     .px-lg-#{$i} {
//       padding-right: $i * $space !important;
//     }

//     .pb-lg-#{$i},
//     .py-lg-#{$i} {
//       padding-bottom: $i * $space !important;
//     }

//     .pl-lg-#{$i},
//     .px-lg-#{$i} {
//       padding-left: $i * $space !important;
//     }

//     .m-lg-n#{$i} {
//       margin: -$i * $space !important;
//     }

//     .mt-lg-n#{$i},
//     .my-lg-n#{$i} {
//       margin-top: -$i * $space !important;
//     }

//     .mr-lg-n#{$i},
//     .mx-lg-n#{$i} {
//       margin-right: -$i * $space !important;
//     }

//     .mb-lg-n#{$i},
//     .my-lg-n#{$i} {
//       margin-bottom: -$i * $space !important;
//     }

//     .ml-lg-n#{$i},
//     .mx-lg-n#{$i} {
//       margin-left: -$i * $space !important;
//     }
//   }

//   .m-lg-auto {
//     margin: auto !important;
//   }

//   .mt-lg-auto,
//   .my-lg-auto {
//     margin-top: auto !important;
//   }

//   .mr-lg-auto,
//   .mx-lg-auto {
//     margin-right: auto !important;
//   }

//   .mb-lg-auto,
//   .my-lg-auto {
//     margin-bottom: auto !important;
//   }

//   .ml-lg-auto,
//   .mx-lg-auto {
//     margin-left: auto !important;
//   }
// }

// @media (min-width: 1200px) {
//   @for $i from 0 through 10 {
//     .m-xl-#{$i} {
//       margin: $i * $space !important;
//     }

//     .mt-xl-#{$i},
//     .my-xl-#{$i} {
//       margin-top: $i * $space !important;
//     }

//     .mr-xl-#{$i},
//     .mx-xl-#{$i} {
//       margin-right: $i * $space !important;
//     }

//     .mb-xl-#{$i},
//     .my-xl-#{$i} {
//       margin-bottom: $i * $space !important;
//     }

//     .ml-xl-#{$i},
//     .mx-xl-#{$i} {
//       margin-left: $i * $space !important;
//     }

//     .p-xl-#{$i} {
//       padding: $i * $space !important;
//     }

//     .pt-xl-#{$i},
//     .py-xl-#{$i} {
//       padding-top: $i * $space !important;
//     }

//     .pr-xl-#{$i},
//     .px-xl-#{$i} {
//       padding-right: $i * $space !important;
//     }

//     .pb-xl-#{$i},
//     .py-xl-#{$i} {
//       padding-bottom: $i * $space !important;
//     }

//     .pl-xl-#{$i},
//     .px-xl-#{$i} {
//       padding-left: $i * $space !important;
//     }

//     .m-xl-n#{$i} {
//       margin: -$i * $space !important;
//     }

//     .mt-xl-n#{$i},
//     .my-xl-n#{$i} {
//       margin-top: -$i * $space !important;
//     }

//     .mr-xl-n#{$i},
//     .mx-xl-n#{$i} {
//       margin-right: -$i * $space !important;
//     }

//     .mb-xl-n#{$i},
//     .my-xl-n#{$i} {
//       margin-bottom: -$i * $space !important;
//     }

//     .ml-xl-n#{$i},
//     .mx-xl-n#{$i} {
//       margin-left: -$i * $space !important;
//     }
//   }

//   .m-xl-auto {
//     margin: auto !important;
//   }

//   .mt-xl-auto,
//   .my-xl-auto {
//     margin-top: auto !important;
//   }

//   .mr-xl-auto,
//   .mx-xl-auto {
//     margin-right: auto !important;
//   }

//   .mb-xl-auto,
//   .my-xl-auto {
//     margin-bottom: auto !important;
//   }

//   .ml-xl-auto,
//   .mx-xl-auto {
//     margin-left: auto !important;
//   }
// }