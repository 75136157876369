@import './../../styles/scss/Grid.scss';
@import './../../styles/scss/Variables.scss';
@import './../../styles/scss/Text.scss';

.input-wrapper {
  margin-bottom: 1.5em;

  &.invalid-value .input-helper {
    color: $warning;
    font-weight: bold;
  }

  &.invalid-value input {
    border: 1.5px solid $warning;

    +label {
      color: $warning;
    }
  }
}

.input-helper-wrapper {
  display: flex;
  justify-content: flex-end;
}

.input-hidden {
  display: none;
}

.input-inner {
  @extend .flex;
  @extend .flex-column;
  position: relative;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  width: 100%;
  background: white;
  border-radius: .4em;

  input {
    border: 1.5px solid $grey;
    padding: 1em;
    height: inherit;
    outline: none;
    font-size: inherit;
    width: inherit;
    border-radius: inherit;
    transition: .2s ease-in-out;

    &:focus {
      border: 1.5px solid #ddd;
    }


    &:focus+label,
    &.dirty-value+label,
    &.has-value+label {
      transform: scale(0.8) translate(0, -70%);
      transform-origin: 0;
      left: 10px;
      top: 0;
      padding: 0 6px;
      background: inherit;
    }

    &.dirty-value.invalid-value {
      border: 1.5px solid $warning;

      +label {
        color: $warning;
      }
    }
  }

  label {
    display: inline-block;
    position: absolute;
    vertical-align: middle;
    pointer-events: none;
    height: auto;
    line-height: normal;
    overflow: initial;
    left: 1.2em;
    color: $grey;
    transition: .2s ease-in-out;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.input-helper {
  @extend .lead;
  text-align: right;
  width: 100%;
}

.input-helper-wrapper a {
  display: inline-block;
}