.buttonWpp {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10000;
  bottom: 6%;
  right: 3%;
  padding: 8px;
  margin: 0;
  color: #FFF;
  background: #eee;
  font-size: 16px;
  font-family: 'Muli';
  cursor: pointer;
  outline: none;
  height: 44px;
  width: 44px;
  max-height: 44px;
  max-width: 44px;
  border: none;
  border-radius: 50%;
  animation: wppMove 5s infinite;

  @media screen and(max-width: 768px) {
    bottom: 86px;
    right: 16px;
    height: 32px;
    width: 32px;
    max-height: 32px;
    max-width: 32px;
  }

  img {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

@keyframes wppMove {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}