@import './Variables.scss';

h1 {
  font-size: 3rem;

  @media screen and (min-width: 768px) {
    font-size: 3rem;
  }
}

h2 {
  font-size: 2.4rem;

  @media screen and (min-width: 768px) {
    font-size: 2.4rem;
  }
}

h3 {
  font-size: 1.8rem;

  @media screen and (min-width: 768px) {
    font-size: 1.8rem;
  }
}

h4 {
  font-size: 1.4rem;

  @media screen and (min-width: 768px) {
    font-size: 1.4rem;
  }
}

h5 {
  font-size: 1.1rem;

  @media screen and (min-width: 768px) {
    font-size: 1.1rem;
  }
}

h6 {
  font-size: .9rem;

  @media screen and (min-width: 768px) {
    font-size: .9rem;
  }
}

p {
  font-size: .9rem;
}

.bold {
  font-weight: bold;
}

.title {
  color: $text-primary;
  font-family: 'Overpass', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-primary {
  color: rgba($color: $text-primary, $alpha: 1);

  &.light {
    color: rgba($color: $primary, $alpha: 1);
  }
}

.lead {
  color: rgba($color: $text-primary, $alpha: .6);

  &.opacity-1 {
    color: rgba($color: $text-primary, $alpha: 1);
  }

  &.opacity-half {
    color: rgba($color: $text-primary, $alpha: .5);
  }
}

.text-white {
  color: rgba($color: $white, $alpha: 1);
}

.lead.text-white {
  color: rgba($color: $white, $alpha: .5);

  &.opacity-1 {
    color: rgba($color: $white, $alpha: 1);
  }

  &.opacity-half {
    color: rgba($color: $white, $alpha: .5);
  }
}