.end-register-message-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  background: $secondary;
  z-index: 10000;
  color: $light;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: currentColor;
  }
}