.status-0 {
  background: rgba(136, 158, 163, 0.25) !important;
  color: #889EA3 !important;
}

.status-1 {
  background: rgba(136, 158, 163, 0.25) !important;
  color: #889EA3 !important;
}

.status-2 {
  background: rgba(72, 130, 134, 0.15) !important;
  color: #488286 !important;
}

.status-3 {
  background: rgba(58, 124, 165, 0.2) !important;
  color: #3A7CA5 !important;
}

.status-4 {
  background: rgba(90, 170, 149, 0.2) !important;
  color: #5AAA95 !important;
}

.status-5 {
  background: rgba(90, 170, 149, 0.2) !important;
  color: #5AAA95 !important;
}

.status-6 {
  background: rgba(201, 112, 100, 0.2) !important;
  color: #C97064 !important;
}