.side-img {
  width: auto;
  height: 100vh;
  overflow: hidden;
  top: 0;
  margin-top: 0%;
}

.col-1 {
  flex: 1;
}

.col-2 {
  flex: 2;
}

.mobile-text-form {
  position: absolute;
  top: 16vh;
}