@import './../../styles/scss/Variables.scss';

.button {
  font-family: 'Muli', sans-serif;
  flex: initial !important;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: none;
  border: 0;
  padding: .7em 1em;
  border-radius: 5em;
  min-width: 160px;
  width: auto;
  font-size: 1em;
  font-weight: 500;
  transition: .6s ease-in;
  pointer-events: visible;
  text-decoration: none;

  >* {
    pointer-events: none;
  }

  &.block {
    width: 100% !important;
  }
}

.button * {
  flex: initial !important;
}

.button svg {
  margin-right: 10px;
}

.button.only-icon svg {
  margin-right: 0px;
}

.button.primary {
  background: $primary;
  background: linear-gradient(90deg, rgba($color: $primary, $alpha: 1), rgba($color: $secondary, $alpha: 1));
  color: #FFF;

  &:hover {
    cursor: pointer;
    background: linear-gradient(90deg, rgba($color: $primary, $alpha: .7), rgba($color: $secondary, $alpha: .7));
    color: #FFF;
  }
}

.button.outline {
  background: transparent;
  border: 2px solid $primary;
  color: $primary;
  transition: .1s ease-in;

  &:hover {
    cursor: pointer;
    background: linear-gradient(90deg, rgba($color: $primary, $alpha: 1), rgba($color: $secondary, $alpha: 1));
    color: #FFF;
  }
}

.button.balance {
  background: rgba($color: #FFFFFF, $alpha: .3);
  color: $grey;
  transition: .2s ease-in;
  border-radius: .4em;

  &:hover {
    cursor: pointer;
    background: rgba($color: #FFFFFF, $alpha: .6);
  }
}

.button.transparent {
  background: rgba($color: #000000, $alpha: 0);
  color: $grey;
  transition: .2s ease-in;

  &:hover {
    cursor: pointer;
    color: #000;
  }
}

.button.menu-trigger {
  background: rgba($color: #000000, $alpha: 0);
  color: #FFFFFF;
  transition: .2s ease-in;

  &:hover {
    cursor: pointer;
    background: rgba($color: #FFFFFF, $alpha: 0.2);
  }
}

.button:disabled {
  background: rgba($color: #000000, $alpha: 0.2);
  color: #FFFFFF;
  transition: .2s ease-in;
}

.sm {
  padding: .5em 1em;
  min-width: 130px;
  font-size: .9em;
}

.md {
  padding: .7em 1em;
  min-width: 150px;
  font-size: 1em;
}

.lg {
  padding: 1em 1em;
  min-width: 150px;
  font-size: 1.1em;
}

.only-icon {
  min-width: 40px;
  width: 40px;
  max-width: 40px;
  padding: .7em 0;
}

.p-button.only-icon {
  min-width: 50px;
  width: 50px;
  max-width: 50px;
  padding: 0.7em 0 !important;
}

.faq {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100%;
  justify-content: space-between;
}