@import '../../styles/scss/Variables.scss';

.panel .text-primary {
  transform: scale(1);
  transform-origin: left;
  transition: .4s ease-in;
}

ul,
ol {
  list-style: none;

  &.type-disc {
    list-style: disc;
  }

  &.type-dec {
    list-style: decimal;
  }
}

.list {
  margin-left: 20px;
  padding: 10px 0;

  li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.panel-faq {
  .panel-body {
    height: 0;
    padding-bottom: 0;
    overflow: hidden;
    transition: .255s ease-in;
  }

  h6 {
    text-align: left;
  }

  &.active-inside {
    .panel-body {
      padding-bottom: 1em;
      height: auto;
      overflow: auto;
    }
  }
}

.accordion-faq {
  .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 0.85em 0;
  }

  .p-accordion .p-accordion-tab {
    box-shadow: none;
    border-bottom: 1px solid rgba($color: $primary, $alpha: 0.3);

    &:last-child {
      border-bottom: 0px;
    }
  }

  .p-accordion p {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}