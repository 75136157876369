@import './../../styles/scss/Variables.scss';

.divider-wrapper {
  position: relative;
  z-index: -1;
  flex: 1;

  &.z-index {
    z-index: 0;
  }

  .divider-inner {
    background: linear-gradient(to right, rgba($color: $support, $alpha: .3), rgba($color: $support, $alpha: 0));

    &.height-1 {
      height: 1px;
    }

    &.height-2 {
      height: 2px;
    }

    &.height-3 {
      height: 3px;
    }
  }

  .divider-inner-solid {
    background: $grey;

    &.standby {
      background: $light;
    }

    &.active,
    &.finished {
      background: rgba($color: $primary, $alpha: 1);
    }
  }
}